<template>
  <div class="relative flex flex-col min-w-0 break-words w-full">
    <div class="rounded-t mb-0 pb-3 border-0">
      <div class="flex flex-wrap items-center">
        <div class="relative w-full max-w-full flex-grow flex-1">
          <h3 class="text-3xl text-blueGray-700 font-light mt-1 mx-5">
            Collaboration Spaces
          </h3>
        </div>
        <div class="text-right mt-3">
          <router-link to="/admin/collaboration-space/demo-wizard">
            <span class="rounded-lg px-3 py-2 bg-blueGray-500 text-white">
              <i class="fa fa-sm fa-plus"></i> Demo
            </span>
          </router-link>
        </div>
      </div>
    </div>
    <DataTable
      class=""
      :admin-prefix="adminPrefix"
      :api-prefix="apiPrefix"
      :singular-label="singularLabel"
      :plural-label="pluralLabel"
      :test-boolean-key="testBooleanKey"
      :columns="columns"
      :create-form="createForm"
      :update-form="updateForm"
      :actions="actions"
      :nested-level="0"
    />
  </div>
</template>
<script>
import { utils } from "common-frontend";
import DataTable from "./DataTable.vue";

const { logoUrl, avatarUrl } = utils;

export default {
  name: "Roles",
  components: {
    DataTable,
  },
  data() {
    return {
      singularLabel: "collaboration space",
      pluralLabel: "collaboration spaces",
      adminPrefix: "collaboration-spaces",
      apiPrefix: "collaboration-space",
      testBooleanKey: "isTestAccount",
      columns: {
        isTestAccount: {
          type: "testBoolean",
          label: "Is Test Account",
          hidden: "never",
          sort: {
            allow: false,
          },
          filter: {
            allow: true,
            matchType: "testBoolean",
            default: { label: "No", value: false },
          },
        },
        hashedId: {
          type: "formattedString",
          label: "Collaboration Space ID",
          hidden: "yes",
          format(row) {
            const els = [];
            els.push(
              `<span class="text-xs uppercase font-mono text-nowrap">grp-${row.hashedId}</span>`,
            );
            return `
              <span class="text-nowrap">
                ${els.join("")}
              </span>
            `;
          },
          sort: {
            allow: false,
          },
          filter: {
            allow: true,
            matchType: "hashedId",
            prefix: "grp-",
          },
        },
        createdAt: {
          type: "absoluteDate",
          label: "Created At",
          sort: {
            allow: true,
            default: "desc",
          },
          filter: {
            allow: false,
            matchType: "dateRange",
            value: "",
            // config: https://chmln.github.io/flatpickr/options/
            config: {
              mode: "range",
              maxDate: "today",
            },
          },
        },
        avatar: {
          type: "avatarWithActivityLevel",
          label: "Avatar",
          format(row) {
            return logoUrl(row);
          },
          sort: {
            allow: false,
          },
          filter: {
            allow: false,
          },
        },
        "organization.name": {
          type: "formattedString",
          label: "Organization",
          format(row) {
            const els = [];
            if (row.organization?.name) {
              els.push(
                `<span class="text-nowrap">${row.organization.name}</span>`,
              );
              els.push(
                `<button class="text-xs bg-porcelain-300 px-2 py-1 ml-2 text-white rounded"><a href="/admin/organization/edit/${row.organization.hashedId}" target="_blank"><i aria-hidden="true" class="fa-solid fa-arrow-up-right-from-square m-[2.5px]"></i></a></button>`,
              );
            } else {
              els.push(`<span class="text-nowrap">No organization</span>`);
            }
            return `
              <span class="text-nowrap">
                ${els.join("")}
              </span>
            `;
          },
          sort: {
            allow: true,
            default: null,
          },
          filter: {
            allow: true,
            matchType: "wildcard",
            value: "",
          },
        },
        name: {
          type: "string",
          label: "Name",
          sort: {
            allow: true,
            default: null,
          },
          filter: {
            allow: true,
            matchType: "wildcard",
            value: "",
          },
        },
        calc_ResearchCredits: {
          type: "decimalTimesOneHundred",
          label: "Research Credit Balance",
          sort: {
            allow: true,
            default: null,
          },
          filter: {
            allow: false,
          },
        },
        calc_AutomatedSourcingCredits: {
          type: "decimalTimesOneHundred",
          label: "Automated Sourcing Credit Balance",
          sort: {
            allow: true,
            default: null,
          },
          filter: {
            allow: false,
          },
        },
        metadata: {
          type: "metadataList",
          label: "Metadata",
          format(row) {
            const els = [];
            const stages = [];
            const sizes = [];
            const industries = [];
            row.companyStages.forEach((companyStage) => {
              stages.push({
                icon: String.fromCodePoint(
                  `0x${companyStage.companyStage.emoji}`,
                ),
                popover: companyStage.companyStage.label,
              });
            });
            row.companySizes.forEach((companySize) => {
              sizes.push({
                icon: String.fromCodePoint(
                  `0x${companySize.companySize.emoji}`,
                ),
                popover: companySize.companySize.label,
              });
            });
            row.industries.forEach((industry) => {
              industries.push({
                icon: String.fromCodePoint(`0x${industry.industry.emoji}`),
                popover: industry.industry.label,
              });
            });
            if (stages.length) {
              els.push({
                label: "Stages",
                items: stages,
              });
            }
            if (sizes.length) {
              els.push({
                label: "Sizes",
                items: sizes,
              });
            }
            if (industries.length) {
              els.push({
                label: "Industries",
                items: industries,
              });
            }
            return els;
          },
          sort: {
            allow: false,
          },
          filter: {
            allow: false,
          },
        },
        suggestedProjectsLength: {
          type: "formattedString",
          label: "Suggested Projects",
          format(row) {
            const els = [];
            row.suggestedWork.forEach((work) => {
              els.push(`<span>${work.suggestedWorkTitle}</span>`);
            });
            return `
              <span class="text-nowrap">
                ${els.join("<br>")}
              </span>
            `;
          },
          sort: {
            allow: false,
          },
          filter: {
            allow: false,
          },
        },
        representative: {
          type: "formattedString",
          label: "Representative(s)",
          format(row) {
            const els = [];
            const reps = row.collaborationSpaceRoles.filter((role) => {
              return role.role?.name === "NotedSource Representative";
            });
            reps.forEach((rep) => {
              els.push(`${rep.person.firstName} ${rep.person.lastName}`);
            });
            return `
              <span class="text-nowrap">
                ${els.join("<br>")}
              </span>
            `;
          },
          sort: {
            allow: false,
          },
          filter: {
            allow: false,
          },
        },
      },
      createForm: {
        name: {
          label: "Name:",
          placeholder: "Enter name",
          type: "string",
          default: "",
          value: "",
          validatorTypes: ["required"],
        },
        isTestAccount: {
          label: "Test Account:",
          checkboxLabel:
            "This is a test account (exclude from search results, etc.)",
          type: "boolean",
          value: "",
          default: "",
          validatorTypes: [],
        },
        isAutomatedSourcingEnabled: {
          label: "Is Automated Sourcing Enabled?:",
          checkboxLabel: "Automated Sourcing is Enabled",
          type: "boolean",
          value: "",
          default: "",
          validatorTypes: [],
        },
        isResearchConceptsEnabled: {
          label: "Is Research Concepts Enabled?:",
          checkboxLabel: "Research Concepts is Enabled",
          type: "boolean",
          value: "",
          default: "",
          validatorTypes: [],
        },
      },
      updateForm: {
        hashedId: {
          type: "hidden",
          value: "",
          default: "",
        },
        name: {
          label: "Name:",
          placeholder: "Enter name",
          type: "string",
          value: "",
          default: "",
          validatorTypes: ["required"],
        },
        isTestAccount: {
          label: "Test Account:",
          checkboxLabel:
            "This is a test account (exclude from search results, etc.)",
          type: "boolean",
          value: "",
          default: "",
          validatorTypes: ["required"],
        },
        isAutomatedSourcingEnabled: {
          label: "Is Automated Sourcing Enabled?:",
          checkboxLabel: "Automated Sourcing is Enabled",
          type: "boolean",
          value: "",
          default: "",
          validatorTypes: [],
        },
        isResearchConceptsEnabled: {
          label: "Is Research Concepts Enabled?:",
          checkboxLabel: "Research Concepts is Enabled",
          type: "boolean",
          value: "",
          default: "",
          validatorTypes: [],
        },
        people: {
          label: "People",
          type: "dataTable",
          value: "",
          singularLabel: "person",
          pluralLabel: "people",
          adminPrefix: "people",
          apiPrefix: "person",
          parentKey: "collaboration_space_roles.collaboration_space.hashed_id",
          columns: {
            avatar: {
              type: "avatarWithActivityLevel",
              label: "Avatar",
              format(row) {
                return avatarUrl(null, row);
              },
              sort: {
                allow: false,
              },
              filter: {
                allow: false,
              },
            },
            firstName: {
              type: "string",
              label: "First Name",
              sort: {
                allow: true,
                default: null,
              },
              filter: {
                allow: true,
                matchType: "wildcard",
                value: "",
              },
            },
            lastName: {
              type: "string",
              label: "Last Name",
              sort: {
                allow: true,
                default: null,
              },
              filter: {
                allow: true,
                matchType: "wildcard",
                value: "",
              },
            },
            emailAddress: {
              type: "formattedString",
              label: "Email Address",
              format(row) {
                const els = [];
                els.push(`${row.calc_EmailAddress}`);
                if (row.primaryEmailAddress.verifiedAt) {
                  els.push(`<i class="fas fa-check-square ml-2"></i>`);
                }
                return `
                  <span class="text-nowrap">
                    ${els.join("")}
                  </span>
                `;
              },
              sort: {
                allow: true,
                default: null,
              },
              filter: {
                allow: true,
                matchType: "wildcard",
                value: "",
              },
            },
            role: {
              type: "formattedString",
              label: "Role",
              format(row, vm) {
                const els = [];
                const thisRole = row.collaborationSpaceRoles.find((role) => {
                  return (
                    role.collaborationSpace.hashedId === vm.parentRow.hashedId
                  );
                });
                els.push(`${thisRole?.role?.name}`);
                return `
                  <span class="text-nowrap">
                    ${els.join("")}
                  </span>
                `;
              },
              sort: {
                allow: true,
                default: null,
              },
              filter: {
                allow: true,
                matchType: "wildcard",
                value: "",
              },
            },
          },
          createForm: {
            hashedId: {
              label: "Collaboration Space Hashed ID:",
              default(row) {
                return row.hashedId;
              },
              type: "hidden",
              validatorTypes: ["required"],
            },
            person: {
              label:
                "Person (First, Last or Full Name) / Primary Email Address",
              placeholder: "Select person",
              type: "autocomplete-select",
              default: [],
              value: [],
              validatorTypes: ["required"],
              idKey: "hashedId",
              labelKey: "fullName",
              labelFunction(row) {
                return `${row.firstName} ${row.lastName} (${row.calc_EmailAddress}, per-${row.hashedId})`;
              },
              autocompleteEndpoint: "person",
              searchOnFocus: false,
            },
            role: {
              label: "Role",
              placeholder: "Select role",
              type: "autocomplete-select",
              default: [],
              value: [],
              validatorTypes: ["required"],
              idKey: "hashedId",
              labelKey: "name",
              autocompleteEndpoint: "role",
              filters: { role_type: "collaboration_space" },
              searchOnFocus: true,
            },
          },
          updateForm: {},
          actions: {
            create: {
              allow: true,
              endpoint(hashedId) {
                return `collaboration-space/${hashedId}/add-person/`;
              },
            },
            linkToRecord: {
              allow: true,
              formatUrl(row) {
                return `profiles/edit/${row.profile.hashedId}`;
              },
            },
            update: {
              allow: false,
            },
            delete: {
              allow: false,
            },
            menuItems: [
              {
                label: "Make Manager",
                qualifier(row, vm) {
                  const thisRole = row.collaborationSpaceRoles.find((role) => {
                    return (
                      role.collaborationSpace.hashedId === vm.parentRow.hashedId
                    );
                  });
                  return thisRole.role.name === "Client";
                },
                confirmFirst: true,
                confirmationMessage:
                  "Are you sure you want to make this person a manager of this Collaboration Space?",
                action(event, row, vm) {
                  const url = `collaboration-space/${vm.parentRow.hashedId}/change-role/`;
                  return [
                    "apiAction",
                    "postReload",
                    url,
                    { person: { id: row.hashedId }, roleName: "manager" },
                  ];
                },
              },
              {
                label: "Make Non-Manager",
                qualifier(row, vm) {
                  const thisRole = row.collaborationSpaceRoles.find((role) => {
                    return (
                      role.collaborationSpace.hashedId === vm.parentRow.hashedId
                    );
                  });
                  return thisRole.role.name === "Manager";
                },
                confirmFirst: true,
                confirmationMessage:
                  "Are you sure you want to remove this person's permission to manage this Collaboration Space?",
                action(event, row, vm) {
                  const url = `collaboration-space/${vm.parentRow.hashedId}/change-role/`;
                  return [
                    "apiAction",
                    "postReload",
                    url,
                    { person: { id: row.hashedId }, roleName: "client" },
                  ];
                },
              },
              {
                label: "Remove from Space",
                qualifier() {
                  return true;
                },
                confirmFirst: true,
                confirmationMessage:
                  "Are you sure you want to remove this person from this Collaboration Space?",
                action(event, row, vm) {
                  const url = `collaboration-space/${vm.parentRow.hashedId}/remove-person/`;
                  return [
                    "apiAction",
                    "postReload",
                    url,
                    { person: { id: row.hashedId } },
                  ];
                },
              },
              {
                label: "Get Magic Link",
                qualifier() {
                  return true;
                },
                action(event, row) {
                  const url = `person/get-magic-link/`;
                  return [
                    "apiAction",
                    "postCopy",
                    url,
                    { emailAddress: row.calc_EmailAddress },
                  ];
                },
              },
            ],
          },
        },
        collaborationRequests: {
          label: "Collaboration Requests",
          type: "dataTable",
          value: "",
          singularLabel: "collaboration request",
          pluralLabel: "collaboration requests",
          adminPrefix: "collaboration-requests",
          apiPrefix: "work/request",
          parentKey: "collaboration_space.hashed_id",
          createForm: {},
          columns: {
            hashedId: {
              type: "formattedString",
              label: "Collaboration Space ID",
              format(row) {
                const els = [];
                els.push(
                  `<span class="text-xs uppercase font-mono text-nowrap">cr-${row.hashedId}</span>`,
                );
                return `
                  <span class="text-nowrap">
                    ${els.join("")}
                  </span>
                `;
              },
              sort: {
                allow: false,
              },
              filter: {
                allow: true,
                matchType: "hashedId",
                prefix: "grp-",
              },
            },
            createdAt: {
              type: "relativeDate",
              label: "Created At",
              sort: {
                allow: true,
                default: "desc",
              },
              filter: {
                allow: true,
                matchType: "dateRange",
                value: "",
                // config: https://chmln.github.io/flatpickr/options/
                config: {
                  mode: "range",
                  maxDate: "today",
                },
              },
            },
            title: {
              type: "string",
              label: "Title",
              sort: {
                allow: true,
                default: null,
              },
              filter: {
                allow: true,
                matchType: "wildcard",
                value: "",
              },
            },
            status: {
              type: "enum",
              label: "Status",
              sort: {
                allow: true,
                default: null,
              },
              filter: {
                allow: true,
                matchType: "wildcard",
                value: "",
              },
            },
          },
          updateForm: {
            hashedId: {
              type: "hidden",
              value: "",
              default: "",
            },
            title: {
              label: "Title:",
              placeholder: "Enter title",
              type: "string",
              value: "",
              default: "",
              validatorTypes: ["required"],
            },
            description: {
              label: "Description:",
              placeholder: "Enter name",
              type: "text",
              value: "",
              default: "",
              validatorTypes: ["required"],
            },
            status: {
              label: "Status",
              placeholder: "Select status",
              type: "autocomplete-select",
              default: [],
              value: [],
              validatorTypes: [],
              idKey: "value",
              labelKey: "label",
              autocompleteEndpoint: "enum/CollaborationRequestStatus",
              searchOnFocus: true,
            },
            isAnonymized: {
              label: "Hide Researcher Names:",
              checkboxLabel:
                "This collaboration request should hide names from the client.",
              type: "boolean",
              value: "",
              default: "",
            },
            deliverableType: {
              label: "Deliverable Type",
              placeholder: "Select deliverable type",
              type: "autocomplete-select",
              default: [],
              value: [],
              validatorTypes: [],
              idKey: "value",
              labelKey: "label",
              autocompleteEndpoint: "enum/DeliverableType",
              searchOnFocus: true,
            },
            engagementTimeline: {
              label: "Start Date",
              placeholder: "Select when to start",
              type: "autocomplete-select",
              default: [],
              value: [],
              validatorTypes: [],
              idKey: "value",
              labelKey: "label",
              autocompleteEndpoint: "enum/EngagementTimeline",
              searchOnFocus: true,
            },
            engagementDuration: {
              label: "Anticipated Duration",
              placeholder: "Select how long this project will last",
              type: "autocomplete-select",
              default: [],
              value: [],
              validatorTypes: [],
              idKey: "value",
              labelKey: "label",
              autocompleteEndpoint: "enum/EngagementDuration",
              searchOnFocus: true,
            },
            creditBudget: {
              label: "Credit Budget (x100):",
              placeholder: "Enter credit budget (times 100)",
              type: "string",
              value: "",
              default: "",
            },
          },
          actions: {
            update: {
              allow: true,
            },
            linkToRecord: {
              allow: true,
              formatUrl(row) {
                return `collaboration-requests/edit/${row.hashedId}`;
              },
            },
            delete: {
              allow: false,
            },
            menuItems: [
              {
                label: "Search for Profiles",
                qualifier() {
                  return true;
                },
                action(event, row) {
                  const url = `${
                    import.meta.env.VITE_ADMIN_BASE_URL
                  }/admin/collaboration-requests/detail/${row.hashedId}`;
                  return ["visitUrl", "get", url];
                },
              },
            ],
          },
        },
        creditTransactions: {
          label: "Credit Transactions",
          type: "dataTable",
          value: "",
          singularLabel: "credit transaction",
          pluralLabel: "credit transactions",
          adminPrefix: "credit-transactions",
          apiPrefix: "collaboration-space-credit-transaction",
          parentKey: "collaboration_space.hashed_id",
          columns: {
            creditType: {
              type: "formattedString",
              label: "Credit Type",
              format(row) {
                const els = [];
                els.push(`${row.creditType.label}`);
                return `
                  <span class="text-nowrap">
                    ${els.join("")}
                  </span>
                `;
              },
              sort: {
                allow: true,
                default: null,
              },
              filter: {
                allow: true,
                matchType: "wildcard",
                value: "",
              },
            },
            transactionType: {
              type: "formattedString",
              label: "Transaction Type",
              format(row) {
                const els = [];
                els.push(`${row.transactionType.label}`);
                return `
                  <span class="text-nowrap">
                    ${els.join("")}
                  </span>
                `;
              },
              sort: {
                allow: true,
                default: null,
              },
              filter: {
                allow: true,
                matchType: "wildcard",
                value: "",
              },
            },
            quantity: {
              type: "formattedString",
              label: "Quantity",
              format(row) {
                const els = [];
                if (
                  ["CREDIT", "TRANSFER_IN"].indexOf(row.transactionType.value) >
                  -1
                ) {
                  els.push("+");
                } else {
                  els.push("-");
                }
                els.push(`${row.quantity / 100}`);
                return `
                  <span class="text-nowrap">
                    ${els.join("")}
                  </span>
                `;
              },
              sort: {
                allow: true,
                default: null,
              },
              filter: {
                allow: true,
                matchType: "wildcard",
                value: "",
              },
            },
            costCents: {
              type: "formattedString",
              label: "Client Cost (For CREDIT)",
              format(row) {
                const els = [];
                els.push(
                  `<span>${
                    row?.costCents ? `$${row?.costCents / 100}` : `--` // eslint-disable-line
                  }</span>`,
                );
                return `
                  <span class="text-nowrap">
                    ${els.join("")}
                  </span>
                `;
              },
              sort: {
                allow: false,
                default: null,
              },
              filter: {
                allow: false,
              },
            },
            clientReflectedAt: {
              type: "absoluteDate",
              label: "Client Reflected At",
              sort: {
                allow: true,
                default: null,
              },
              filter: {
                allow: true,
                matchType: "wildcard",
                value: "",
              },
            },
            clientDescription: {
              type: "string",
              label: "Client Description",
              sort: {
                allow: false,
              },
              filter: {
                allow: true,
                matchType: "wildcard",
                value: "",
              },
            },
          },
          createForm: {
            hashedId: {
              label: "Collaboration Space Hashed ID:",
              default(row) {
                return row.hashedId;
              },
              type: "hidden",
              validatorTypes: ["required"],
            },
            creditType: {
              label: "Credit Type",
              placeholder: "Select credit type",
              type: "autocomplete-select",
              default: [],
              value: [],
              validatorTypes: ["required"],
              idKey: "value",
              labelKey: "label",
              autocompleteEndpoint: "enum/CreditType",
              searchOnFocus: true,
            },
            transactionType: {
              label: "Transaction Type",
              placeholder: "Select transaction type",
              type: "autocomplete-select",
              default: [],
              value: [],
              validatorTypes: ["required"],
              idKey: "value",
              labelKey: "label",
              autocompleteEndpoint:
                "enum/CollaborationSpaceCreditTransactionType",
              searchOnFocus: true,
            },
            quantity: {
              label: "Quantity of Credits",
              type: "decimalTimesOneHundred",
              placeholder: "Number of credits",
              validatorTypes: ["required"],
            },
            costCents: {
              label: "Client Cost (if transaction type is CREDIT)",
              type: "decimalTimesOneHundred",
              placeholder: "Cost to Client",
              value: "",
              default: "",
              validatorTypes: [],
            },
            internalNotes: {
              label: "Internal Notes:",
              placeholder: "Enter internal notes",
              type: "text",
              value: "",
              default: "",
            },
            clientDescription: {
              label: "Client Description:",
              placeholder: "Enter client description",
              type: "text",
              value: "",
              default: "",
            },
            clientReflectedAt: {
              label: "Client Reflected At:",
              placeholder: "Enter date when this should reflect for the client",
              type: "date",
              value: "",
              default: "",
              validatorTypes: ["required"],
            },
          },
          updateForm: {
            hashedId: {
              type: "hidden",
              value: "",
              default: "",
              validatorTypes: ["required"],
            },
            creditType: {
              label: "Credit Type",
              placeholder: "Select credit type",
              type: "autocomplete-select",
              default: [],
              value: [],
              validatorTypes: ["required"],
              idKey: "value",
              labelKey: "label",
              autocompleteEndpoint: "enum/CreditType",
              searchOnFocus: true,
            },
            transactionType: {
              label: "Transaction Type",
              placeholder: "Select transaction type",
              type: "autocomplete-select",
              default: [],
              value: [],
              validatorTypes: ["required"],
              idKey: "value",
              labelKey: "label",
              autocompleteEndpoint:
                "enum/CollaborationSpaceCreditTransactionType",
              searchOnFocus: true,
            },
            quantity: {
              label: "Quantity of Credits",
              type: "decimalTimesOneHundred",
              placeholder: "Number of credits",
              validatorTypes: ["required"],
            },
            costCents: {
              label: "Client Cost (if transaction type is CREDIT)",
              type: "decimalTimesOneHundred",
              placeholder: "Cost to Client",
              value: "",
              default: "",
              validatorTypes: [],
            },
            internalNotes: {
              label: "Internal Notes:",
              placeholder: "Enter internal notes",
              type: "text",
              value: "",
              default: "",
            },
            clientDescription: {
              label: "Client Description:",
              placeholder: "Enter client description",
              type: "text",
              value: "",
              default: "",
            },
            clientReflectedAt: {
              label: "Client Reflected At:",
              placeholder: "Enter date when this should reflect for the client",
              type: "date",
              value: "",
              default: "",
              validatorTypes: ["required"],
            },
          },
          actions: {
            create: {
              allow: true,
            },
            update: {
              allow: true,
            },
            delete: {
              allow: true,
            },
            menuItems: [],
          },
        },
        suggestedWork: {
          label: "Suggested Work",
          type: "dataTable",
          value: "",
          singularLabel: "suggested work",
          pluralLabel: "suggested work",
          adminPrefix: "suggested-work",
          apiPrefix: "suggested-work",
          parentKey: "collaboration_space.hashed_id",
          columns: {
            suggestedWorkTitle: {
              type: "string",
              label: "Title",
              sort: {
                allow: true,
                default: null,
              },
              filter: {
                allow: true,
                matchType: "wildcard",
                value: "",
              },
            },
          },
          createForm: {
            hashedId: {
              label: "Collaboration Space Hashed ID:",
              default(row) {
                return row.hashedId;
              },
              type: "hidden",
              validatorTypes: ["required"],
            },
            suggestedWorkTitle: {
              label: "Title",
              placeholder: "Title of suggested work",
              type: "string",
              default: "",
              value: "",
              validatorTypes: ["required"],
            },
            description: {
              label: "Description",
              placeholder: "Description of suggested work",
              type: "text",
              value: "",
              default: "",
            },
            duration: {
              label: "Duration",
              placeholder: "Proposed duration of suggested work",
              type: "string",
              default: "",
              value: "",
              validatorTypes: [],
            },
            experience: {
              label: "Experience",
              placeholder: "Experience required for selected work",
              type: "string",
              default: "",
              value: "",
            },
          },
          updateForm: {
            hashedId: {
              type: "hidden",
              value: "",
              default: "",
            },
            suggestedWorkTitle: {
              label: "Title",
              placeholder: "Title of suggested work",
              type: "string",
              default: "",
              value: "",
              validatorTypes: ["required"],
            },
            description: {
              label: "Description",
              placeholder: "Description of suggested work",
              type: "text",
              value: "",
              default: "",
            },
            duration: {
              label: "Duration",
              placeholder: "Proposed duration of suggested work",
              type: "string",
              default: "",
              value: "",
              validatorTypes: [],
            },
            experience: {
              label: "Experience",
              placeholder: "Experience required for suggested work",
              type: "string",
              default: "",
              value: "",
            },
            researchAreas: {
              label: "Research Areas",
              type: "dataTable",
              value: "",
              singularLabel: "research area",
              pluralLabel: "research areas",
              adminPrefix: "research-areas",
              apiPrefix: "suggested-work-research-area",
              parentKey: "collaboration_space_suggested_work.hashed_id",
              columns: {
                researchArea: {
                  type: "string",
                  label: "Research Area",
                  sort: {
                    allow: true,
                    default: null,
                  },
                  filter: {
                    allow: true,
                    matchType: "wildcard",
                    value: "",
                  },
                },
              },
              createForm: {
                hashedId: {
                  label: "Collaboration Space Suggested Work Hashed ID:",
                  default(row) {
                    return row.hashedId;
                  },
                  type: "hidden",
                  validatorTypes: ["required"],
                },
                researchArea: {
                  label: "Research Area",
                  placeholder: "Name of Research Area",
                  type: "string",
                  default: "",
                  value: "",
                  validatorTypes: ["required"],
                },
              },
              updateForm: {
                hashedId: {
                  type: "hidden",
                  value: "",
                  default: "",
                },
                researchArea: {
                  label: "Research Area",
                  placeholder: "Name of Research Area",
                  type: "string",
                  default: "",
                  value: "",
                  validatorTypes: ["required"],
                },
              },
              actions: {
                create: {
                  allow: true,
                },
                update: {
                  allow: true,
                },
                delete: {
                  allow: true,
                },
                menuItems: [],
              },
            },
            institutions: {
              label: "Institutions",
              type: "dataTable",
              value: "",
              singularLabel: "institution",
              pluralLabel: "institutions",
              adminPrefix: "institutions",
              apiPrefix: "suggested-work-institution",
              parentKey: "collaboration_space_suggested_work.hashed_id",
              columns: {
                schoolName: {
                  type: "string",
                  label: "School Name",
                  sort: {
                    allow: true,
                    default: null,
                  },
                  filter: {
                    allow: true,
                    matchType: "wildcard",
                    value: "",
                  },
                },
                schoolLocation: {
                  type: "string",
                  label: "School Location",
                  sort: {
                    allow: true,
                    default: null,
                  },
                  filter: {
                    allow: true,
                    matchType: "wildcard",
                    value: "",
                  },
                },
              },
              createForm: {
                hashedId: {
                  label: "Collaboration Space Suggested Work Hashed ID:",
                  default(row) {
                    return row.hashedId;
                  },
                  type: "hidden",
                  validatorTypes: ["required"],
                },
                schoolName: {
                  label: "School Name",
                  placeholder: "Name of Institution",
                  type: "string",
                  default: "",
                  value: "",
                  validatorTypes: ["required"],
                },
                schoolLocation: {
                  label: "School Location",
                  placeholder: "Location of Institution",
                  type: "string",
                  default: "",
                  value: "",
                  validatorTypes: [],
                },
              },
              updateForm: {
                hashedId: {
                  type: "hidden",
                  value: "",
                  default: "",
                },
                schoolName: {
                  label: "School Name",
                  placeholder: "Name of Institution",
                  type: "string",
                  default: "",
                  value: "",
                  validatorTypes: ["required"],
                },
                schoolLocation: {
                  label: "School Location",
                  placeholder: "Location of Institution",
                  type: "string",
                  default: "",
                  value: "",
                  validatorTypes: [],
                },
              },
              actions: {
                create: {
                  allow: true,
                },
                update: {
                  allow: true,
                },
                delete: {
                  allow: true,
                },
                menuItems: [],
              },
            },
          },
          actions: {
            create: {
              allow: true,
            },
            update: {
              allow: true,
            },
            delete: {
              allow: true,
            },
            menuItems: [],
          },
        },
        emailDomains: {
          label: "Email Domains",
          type: "dataTable",
          value: "",
          singularLabel: "email domain",
          pluralLabel: "email domains",
          adminPrefix: "email-domains",
          apiPrefix: "collaboration-space/email-domain",
          parentKey: "collaboration_space.hashed_id",
          columns: {
            domain: {
              type: "string",
              label: "Domain",
              sort: {
                allow: true,
                default: null,
              },
              filter: {
                allow: true,
                matchType: "wildcard",
                value: "",
              },
            },
          },
          createForm: {
            hashedId: {
              label: "Collaboration Space Hashed ID:",
              default(row) {
                return row.hashedId;
              },
              type: "hidden",
              validatorTypes: ["required"],
            },
            domain: {
              label: "Email Domain",
              placeholder: "example.com",
              type: "string",
              default: [],
              value: [],
              validatorTypes: ["required"],
            },
          },
          updateForm: {
            hashedId: {
              type: "hidden",
              value: "",
              default: "",
            },
            domain: {
              label: "Email Domain",
              placeholder: "example.com",
              type: "string",
              default: [],
              value: [],
              validatorTypes: ["required"],
            },
          },
          actions: {
            create: {
              allow: true,
            },
            update: {
              allow: true,
            },
            delete: {
              allow: true,
            },
            menuItems: [],
          },
        },
        industries: {
          label: "Industries",
          type: "dataTable",
          value: "",
          singularLabel: "industry",
          pluralLabel: "industries",
          adminPrefix: "industries",
          apiPrefix: "collaboration-space/industry",
          parentKey: "collaboration_space.hashed_id",
          createForm: {},
          updateForm: {},
          columns: {
            hashedId: {
              type: "formattedString",
              label: "Collaboration Space ID",
              format(row) {
                const els = [];
                els.push(
                  `<span class="text-xs uppercase font-mono text-nowrap">cr-${row.hashedId}</span>`,
                );
                return `
                  <span class="text-nowrap">
                    ${els.join("")}
                  </span>
                `;
              },
              sort: {
                allow: false,
              },
              filter: {
                allow: true,
                matchType: "hashedId",
                prefix: "grp-",
              },
            },
            createdAt: {
              type: "relativeDate",
              label: "Created At",
              sort: {
                allow: true,
                default: "desc",
              },
              filter: {
                allow: true,
                matchType: "dateRange",
                value: "",
                // config: https://chmln.github.io/flatpickr/options/
                config: {
                  mode: "range",
                  maxDate: "today",
                },
              },
            },
            industry: {
              type: "enum",
              label: "Industry",
              sort: {
                allow: true,
                default: null,
              },
              filter: {
                allow: true,
                matchType: "wildcard",
                value: "",
              },
            },
          },
          actions: {
            update: {
              allow: false,
            },
            delete: {
              allow: false,
            },
            menuItems: [],
          },
        },
        companySizes: {
          label: "Company Sizes",
          type: "dataTable",
          value: "",
          singularLabel: "company size",
          pluralLabel: "company sizes",
          adminPrefix: "company-sizes",
          apiPrefix: "collaboration-space/company-size",
          parentKey: "collaboration_space.hashed_id",
          createForm: {},
          updateForm: {},
          columns: {
            hashedId: {
              type: "formattedString",
              label: "Collaboration Space ID",
              format(row) {
                const els = [];
                els.push(
                  `<span class="text-xs uppercase font-mono text-nowrap">cr-${row.hashedId}</span>`,
                );
                return `
                  <span class="text-nowrap">
                    ${els.join("")}
                  </span>
                `;
              },
              sort: {
                allow: false,
              },
              filter: {
                allow: true,
                matchType: "hashedId",
                prefix: "grp-",
              },
            },
            createdAt: {
              type: "relativeDate",
              label: "Created At",
              sort: {
                allow: true,
                default: "desc",
              },
              filter: {
                allow: true,
                matchType: "dateRange",
                value: "",
                // config: https://chmln.github.io/flatpickr/options/
                config: {
                  mode: "range",
                  maxDate: "today",
                },
              },
            },
            companySize: {
              type: "enum",
              label: "Company Size",
              sort: {
                allow: true,
                default: null,
              },
              filter: {
                allow: true,
                matchType: "wildcard",
                value: "",
              },
            },
          },
          actions: {
            update: {
              allow: false,
            },
            delete: {
              allow: false,
            },
            menuItems: [],
          },
        },
        companyStages: {
          label: "Company Stages",
          type: "dataTable",
          value: "",
          singularLabel: "company stage",
          pluralLabel: "company stages",
          adminPrefix: "company-stages",
          apiPrefix: "collaboration-space/company-stage",
          parentKey: "collaboration_space.hashed_id",
          createForm: {},
          updateForm: {},
          columns: {
            hashedId: {
              type: "formattedString",
              label: "Collaboration Space ID",
              format(row) {
                const els = [];
                els.push(
                  `<span class="text-xs uppercase font-mono text-nowrap">cr-${row.hashedId}</span>`,
                );
                return `
                  <span class="text-nowrap">
                    ${els.join("")}
                  </span>
                `;
              },
              sort: {
                allow: false,
              },
              filter: {
                allow: true,
                matchType: "hashedId",
                prefix: "grp-",
              },
            },
            createdAt: {
              type: "relativeDate",
              label: "Created At",
              sort: {
                allow: true,
                default: "desc",
              },
              filter: {
                allow: true,
                matchType: "dateRange",
                value: "",
                // config: https://chmln.github.io/flatpickr/options/
                config: {
                  mode: "range",
                  maxDate: "today",
                },
              },
            },
            companyStage: {
              type: "enum",
              label: "companyStage",
              sort: {
                allow: true,
                default: null,
              },
              filter: {
                allow: true,
                matchType: "wildcard",
                value: "",
              },
            },
          },
          actions: {
            update: {
              allow: false,
            },
            delete: {
              allow: false,
            },
            menuItems: [],
          },
        },
      },
      actions: {
        update: {
          allow: true,
        },
        delete: {
          allow: false,
        },
        menuItems: [
          {
            label: "Update Suggested Work",
            qualifier() {
              return true;
            },
            action(event, row) {
              const url = `collaboration-space/${row.hashedId}/suggest-work/`;
              return ["apiAction", "postReload", url];
            },
          },
          {
            label: "Delete Collaboration Space",
            qualifier(row) {
              return row.isTestAccount;
            },
            confirmFirst: true,
            confirmationMessage:
              "Are you sure you want to delete this collaboration space? Since it is a test account, all connected test accounts will also be removed.",
            action(event, row) {
              const url = `collaboration-space/${row.hashedId}/`;
              return [
                "apiAction",
                "deleteReload",
                url,
                { collaborationSpaceId: row.hashedId },
              ];
            },
          },
        ],
      },
    };
  },
};
</script>
